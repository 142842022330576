<template>
  <v-row class="main-row">
    <v-col md="10" offset-md="1" lg="4" offset-lg="4" align-self="center">
      <v-card class="pa-5 light-blue lighten-5" elevation="24">
        <v-row>
          <v-col cols="8" offset="2">
            <v-img src="@/assets/logo.png"/>
          </v-col>
        </v-row>
        <v-card-text>
          <login-form/>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

export default {
  components: {
    LoginForm: () => import(/* webpackChunkName: "login" */ '../components/forms/LoginForm.vue'),
  },
};
</script>

<style scoped>
.main-row {
  height: 90vh;
}
</style>
